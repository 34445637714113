import React from 'react'

function Social() {
  return (
    <div className="home__social">
      <a href="https://facebook.com/abintjose" className="home__social-icon" target="_blank">
        <i className="uil uil-facebook-f"></i>
      </a>
      <a href="https://twitter/abintjose" className="home__social-icon" target="_blank">
        <i className="uil uil-twitter"></i>
      </a>
      <a href="https://linkedin.com/in/abintjose" className="home__social-icon" target="_blank">
        <i className="uil uil-linkedin"></i>
      </a>
      <a href="https://github.com/abin-xlri" className="home__social-icon" target="_blank">
        <i className="uil uil-github"></i>
      </a>
    </div>
  )
}

export default Social