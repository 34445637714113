import React from 'react'
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">abintjose.com</h1>
        <ul className="footer__list">
          <li><a href="/" className="footer__link">Home</a></li>
          <li><a href="#about" className="footer__link">About me</a></li>
          <li><a href="#services" className="footer__link">Services</a></li>
          <li><a href="#testimonial" className="footer__link">Testimonials</a></li>
        </ul>
        <div className="footer__social">
          <a href="https://facebook.com/abintjose" className="footer__social-link" target="_blank">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="https://twitter/abintjose" className="footer__social-link" target="_blank">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="https://linkedin.com/in/abintjose" className="footer__social-link" target="_blank">
            <i className="bx bxl-linkedin"></i>
          </a>
          <a href="https://github.com/abin-xlri" className="footer__social-link" target="_blank">
            <i className="bx bxl-github"></i>
          </a>
        </div>
        <span className="footer__copyright">&#169; abintjose.com All rights reserved.</span>
      </div>
    </footer>
  )
}

export default Footer